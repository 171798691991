import React, { useState } from 'react';
import UserHeader from '../Header/UserHeader';
import './TekmizCompiler.scss';

const CodeEditor = () => {
  const [language, setLanguage] = useState('cpp');

  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
  };

  return (
    <div className="compilerContainer">
      <UserHeader />
      <div className="content">
        <div className="compilerSidebar">
          <h3>Select Language</h3>
          <select value={language} onChange={handleLanguageChange}>
            <option value="cpp">C++</option>
            <option value="python">Python</option>
            <option value="javascript">JavaScript</option>
            <option value="java">Java</option>
            <option value="c">C</option>
            <option value="php">PHP</option>
          </select>
        </div>
        <div className="compilerEditor">
          <h3>Online {language.toUpperCase()} Compiler</h3>
          <iframe
            className="frame"
            src={`https://www.programiz.com/${language}-programming/online-compiler/`}
            title={`${language.toUpperCase()} Online Compiler`}
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default CodeEditor;
